// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import Login from './Login';
import Hello from './Hello';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Hello />} />
            {/* <Route path="/hello" element={<Hello />} /> */}
        </Routes>
    );
};

export default App;
