// src/Hello.js
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

const Hello = () => {
    const { instance, accounts } = useMsal();
    const [backendData, setBackendData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const backendResponse = await axios.get('https://mcbu-samples.ssp-s1-sandbox.novozymes.cloud/hello', {
                });

                setBackendData(backendResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [instance, accounts]);

    return (
        <div>
            <h1>Hello World!</h1>
            {backendData ? (
                <div>
                    <h2>Data from Backend:</h2>
                    <pre>{JSON.stringify(backendData, null, 2)}</pre>
                </div>
            ) : (
                <p>Loading data from backend...</p>
            )}
        </div>
    );
};

export default Hello;
